import plnPostpaid from "helper/receipt/plnpostpaid";
import plnPrepaid from "helper/receipt/plnprepaid";
import plnNonTaglis from "helper/receipt/plnnontaglis";
import pdam from "helper/receipt/pdam";
import finance from "helper/receipt/finance";
import bpjs from "helper/receipt/bpjs";
import pbb from "helper/receipt/pbb";
import telkom from "helper/receipt/telkom";
import postpaid from "helper/receipt/postpaid";
import mobilePrepaid from "helper/receipt/mobileprepaid";
import banktransfer from "helper/receipt/banktransfer";
import printList from "helper/receipt/printlist";
import secureStorage from "libs/secureStorage";

const receipt = (payload) => {
  const info = secureStorage.getItem("domainInfo");
  payload.appName = info && info.appName ? info.appName : window.location.host;
  const productId = payload.productId ? payload.productId : payload.id;
  const productName = payload.productId ? payload.productName : payload.name;
  // console.log(payload);
  // console.log(payload)
  if (productId === 15) {
    return plnPrepaid(payload);
  } else if (productId === 14) {
    return plnPostpaid(payload);
  } else if (payload.productId === 984) {
    return plnNonTaglis(payload);
  } else if (payload.productCategoryId === 9) {
    return pdam(payload);
  } else if (payload.productCategoryId === 3) {
    return finance(payload);
  } else if ([2, 6, 7, 10, 14].includes(payload.productCategoryId)) {
    // PULSA, PAKET DATA , E-MONEY, E-MONEY DENOM
    return mobilePrepaid(payload);
  } else if (payload.productCategoryId === 5) {
    return postpaid(payload);
  } else if (productName && productName.indexOf("BPJS") !== -1) {
    return bpjs(payload);
  } else if (productName && productName.toUpperCase().includes("PBB")) {
    return pbb(payload);
  } else if ([9, 555].includes(productId)) {
    return telkom(payload);
  } else if ([12].includes(payload.productCategoryId)) {
    return banktransfer(payload);
  } else if (payload.title && payload.title.indexOf("DAFTAR") === 0) {
    return printList(payload);
  }
};
export default receipt;
