import React from "react";

//hook styles class conversion
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import gMainMenuStyle from "assets/jss/material-kit-pro-react/views/gMainMenuStyle.js";
import gModalStyle from "assets/jss/material-kit-pro-react/gModalStyle.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import axios from "axios";
import Config from "config";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/CustomButtons/Button.js";
import { fnumber } from "helper/Helper";

import MakeButton from "views/CustomComponents/MakeButton";
import Badge from "components/Badge/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "components/GCustomInput/CustomInput.js";

import print from "helper/printer";
import modalReceipt from "helper/modalReceipt";
import share from "libs/share";

import Print from "@material-ui/icons/Print";

import { Close, CloudDownload as Download, Share } from "@material-ui/icons";

import moment from "moment";
// import "moment/locale/id";
//import { savePdf} from "libs/pdf"
// moment.locale("id");

const styles = (theme) => ({
  ...gMainMenuStyle,
  ...gModalStyle(theme),
  gridItem: {
    marginTop: 3,
  },
});
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      offset: 0,
      limit: 10,
      isLoaded: false,
      prevY: 0,
      isActive: false,

      filename: "",
      showModal: false,
      modalTitle: "",
      modalBody: [],
      selectedSales: "",
      customerCode: "",
    };
  }
  componentDidMount() {
    this.observer = new IntersectionObserver(
      this.handleObserver, //callback
      {
        root: null, // Page as root
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
    this.observer.observe(this.viewref);
  }
  componentWillUnmount() {
    this.observer.unobserve(this.viewref);
    // this.observer.disconnect()
  }
  handleObserver = (entities, observer) => {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y && this.state.isActive) {
      const offset = this.state.offset + 10;
      this.setState({ offset });
      this.loadHistory({ offset });
    }
    this.setState({ prevY: y });
  };
  loadHistory(params) {
    // console.log({offset:,customerCode})
    const payload = {
      offset:
        params && params.offset !== undefined
          ? params.offset
          : this.state.offset,
      limit: this.state.limit,
      customerCode: this.state.customerCode,
    };
    // console.log(payload)
    axios
      .post(Config.backendUrl + "history/get", payload)
      .then((res) => {
        // console.log(payload,res.data,params && params.customerCode)
        if (params && params.customerCode !== undefined)
          this.setState({
            rows: res.data,
            isLoaded: true,
            isActive: true,
          });
        else
          this.setState({
            rows: [...this.state.rows, ...res.data],
            isLoaded: true,
            isActive: true,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  unloadHistory = () => {
    this.setState({ rows: [], offset: 0, isActive: false });
  };
  handleList = (item) => {
    //console.log(this.state.rows[index].createdAt)
    // const date = item.receiptBody.indexOf('TGL TRANSAKSI')===-1?[['TGL TRANSAKSI',moment(item.createdAt)]]:[]
    const date = !item.receiptBody.date
      ? {
          date: {
            caption: "TGL TRANSAKSI",
            value: moment(item.createdAt),
            // value: moment(item.createdAt).format("DD/MM/YY HH:mm"),
          },
        }
      : {};
    const receiptBody = { ...date, ...item.receiptBody };
    const modalTitle = item.receiptTitle;
    const modalBody = receiptBody;

    console.log({ item, modalBody });
    this.setState({
      showModal: true,
      modalTitle,
      modalBody,
      selectedSales: item,
      filename: `${item.productName.replace(/\s/g, "_")}_${
        item.customerCode
      }_${moment(item.createdAt).format("YYMMDDHHmm")}.pdf`,
    });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  handlePrint = async () => {
    const title = this.state.modalTitle;
    const body = this.state.modalBody;
    const filename = this.state.filename;

    print({
      title,
      body,
      filename,
      original: false,
      ...this.state.selectedSales,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center" className={classes.gridWrapper}>
          <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
            <Input
              id="customerCode"
              formControlProps={{
                // fullWidth: true,
                style: { width: 300 },
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                placeholder: "Masukkan IDPEL / No Pelanggan",
                value: this.state.customerCode,
                onChange: (e) => {
                  if (!e.target.value) {
                    const offset = 0;
                    this.setState({ offset });
                    this.loadHistory({ offset, customerCode: e.target.value });
                  }
                  this.setState({ customerCode: e.target.value });
                },
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    const offset = 0;
                    this.setState({ offset });
                    this.loadHistory({
                      offset: 0,
                      customerCode: this.state.customerCode,
                    });
                  }
                },
                type: "tel",
              }}
              labelText="IDPEL/NO PELANGGAN"
            />
          </GridItem>
          <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
            <List>
              {this.state.rows.length > 0 ? (
                this.state.rows.map((item, index) => (
                  <ListItem key={index}>
                    <MakeButton
                      width="100%"
                      id={index}
                      onClick={() => this.handleList(item)}
                    >
                      <GridContainer
                        justify="center"
                        className={classes.gridWrapper}
                      >
                        <GridItem
                          xl={2}
                          lg={2}
                          md={2}
                          sm={6}
                          xs={12}
                          className={classes.gridItem}
                        >
                          <Badge color="google">{item.productName}</Badge>
                        </GridItem>
                        <GridItem
                          xl={2}
                          lg={2}
                          md={2}
                          sm={6}
                          xs={12}
                          className={classes.gridItem}
                        >
                          {item.customerCode}
                        </GridItem>
                        <GridItem
                          xl={2}
                          lg={2}
                          md={2}
                          sm={6}
                          xs={12}
                          className={classes.gridItem}
                        >
                          Rp. {fnumber(parseInt(item.amount))}
                        </GridItem>
                        <GridItem
                          xl={4}
                          lg={4}
                          md={4}
                          sm={6}
                          xs={12}
                          className={classes.gridItem}
                        >
                          {moment(item.createdAt)
                            .format("DD MMM YYYY HH:mm:ss")
                            .toUpperCase()}
                        </GridItem>
                      </GridContainer>
                    </MakeButton>
                  </ListItem>
                ))
              ) : !this.state.isLoaded ? (
                <CircularProgress color="secondary" />
              ) : null}
            </List>
            <div ref={(myref) => (this.viewref = myref)} />
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={this.state.showModal}
          TransitionComponent={Transition}
          keepMounted
          //onClose={() => this.setState({successModal: false})}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {this.state.modalBody && modalReceipt(this.state.modalBody)}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              round
              onClick={() => this.handleCloseModal("close")}
              color="danger"
            >
              <Close />
              close
            </Button>
            <Button
              round
              onClick={() => this.handlePrint("print")}
              color="instagram"
            >
              <Print />
              Print
            </Button>
            <Button
              round
              justIcon
              onClick={() => {
                const { savePdf } = require("libs/pdf");
                const options = {
                  title: this.state.modalTitle,
                  body: this.state.modalBody,
                  filename: this.state.filename,
                  ...this.state.selectedSales,
                };

                savePdf(options, "PdfReceipt");
              }}
              color="success"
            >
              <Download />
            </Button>
            {navigator.canShare && this.state.modalBody && (
              <Button
                round
                justIcon
                onClick={async () => {
                  const options = {
                    title: this.state.modalTitle,
                    body: this.state.modalBody,
                    filename: this.state.filename,
                    ...this.state.selectedSales,
                  };

                  share(options);
                }}
                color="info"
              >
                <Share />
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
History.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(History);
